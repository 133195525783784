// store/onofficeParams.js
export default {
  parameterCacheId: null,
  apiClaim: null,
  signature: null,
  initialized: false,

  init() {
    if (this.initialized) return;

    const urlParams = new URLSearchParams(window.location.search);
    this.parameterCacheId = urlParams.get("parameterCacheId") || "";
    this.apiClaim = urlParams.get("apiClaim") || "";
    this.signature = urlParams.get("signature") || "";
    this.initialized = true;

    console.log("OnOffice params initialized:", {
      parameterCacheId: this.parameterCacheId,
      apiClaim: this.apiClaim,
      signature: this.signature,
    });
  },

  getParameterCacheId() {
    return this.parameterCacheId;
  },
};
